import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { CustomTable } from '../shared/CustomTable';
import { FILTER_TYPE, SORT_TYPE } from '../shared/ApplicationConstants';
import CommentBox from '../shared/CommentBox';
import './errorDashboard.css';
import { useOktaAuth } from '@okta/okta-react';
import {
  deleteErrorDetails,
  getAllAssignees,
  getAllErrorRecords,
  updateComments,
} from '../../apis/errorRecordsApis';

const mapComments = (records) =>
  records.map((record) => [[record.review_id], record.comments]);

function ErrorDashboard() {
  let history = useHistory();
  const [data, setData] = useState([]); // set data from api call
  const [allAssignee, setAllAssignee] = useState([]); // set list of all assignee
  const [comments, setComments] = useState({});
  const [oldComments, setOldComments] = useState({});
  const [size, setSize] = useState(localStorage.getItem('pageSize') || 10);
  const [loading, setLoading] = useState(false);
  const [errorsToBeDeleted, setErrorsToBeDeleted] = useState([]);
  const { authState } = useOktaAuth();
  const accessToken = authState.accessToken?.accessToken;

  async function fetchData() {
    const allErrors = await getAllErrorRecords(accessToken);
    const allAssignees = await getAllAssignees(accessToken);
    setData(allErrors);
    setAllAssignee(allAssignees);
    const mappedComments = Object.fromEntries(mapComments(allErrors));
    setComments(mappedComments);
    setOldComments(mappedComments);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const clearQueuedChanges = () => {
    setErrorsToBeDeleted([]);
  };

  const onPressSave = (comments) => async () => {
    setLoading(true);
    const commentsToUpdate = Object.fromEntries(
      Object.entries(comments).filter(([_, comment]) => comment !== null)
    );
    await updateComments(oldComments, commentsToUpdate, accessToken);
    await deleteErrorDetails(errorsToBeDeleted, accessToken);
    await fetchData();
    clearQueuedChanges();
    setLoading(false);
  };

  function gotoDetails(row) {
    if (row.error_type === 'SFDC Multiple Grower') {
      history.push({
        pathname: `/sfdetails/${row.review_id}`,
        state: { data: row, allAssignee: allAssignee, id: row.review_id },
      });
    } else {
      history.push({
        pathname: `/details/${row.review_id}`,
        state: { data: row, allAssignee: allAssignee, id: row.review_id },
      });
    }
  }

  const columns = [
    {
      title: 'Request Id',
      dataIndex: 'request_id',
      filter: FILTER_TYPE.TEXT,
      sort: SORT_TYPE.TEXT,
      sortOrd: 'ascend',
    },
    {
      title: 'Logged Date / Time',
      dataIndex: 'created_at',
      filter: FILTER_TYPE.TEXT,
      sort: SORT_TYPE.TEXT,
      sortOrd: 'ascend',
    },
    {
      title: 'Error Type',
      dataIndex: 'error_type',
      filter: FILTER_TYPE.TEXT,
      sort: SORT_TYPE.TEXT,
      sortOrd: 'ascend',
    },
    {
      title: 'AGIIS GLN',
      dataIndex: 'grower_gln',
      filter: FILTER_TYPE.TEXT,
      sort: SORT_TYPE.TEXT,
      sortOrd: 'ascend',
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      render: (col, row) => {
        const assignee = (allAssignee || []).filter(
          (item) => item.id === row.data_steward_id
        );
        if (assignee.length > 0) {
          return `${assignee[0].first_name} ${assignee[0].last_name}`;
        }

        return 'Unassigned';
      },
      filter: FILTER_TYPE.TEXT,
      sort: SORT_TYPE.TEXT,
      sortOrd: 'ascend',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filter: FILTER_TYPE.TEXT,
      sort: SORT_TYPE.TEXT,
      sortOrd: 'ascend',
    },
    {
      title: 'Error ID',
      dataIndex: 'error_type_id',
      filter: FILTER_TYPE.TEXT,
      sort: SORT_TYPE.NUMBER,
      sortOrd: 'ascend',
      render: (col, row) => {
        return (
          <Button type="link" onClick={() => gotoDetails(row)}>
            {col}
          </Button>
        );
      },
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      filter: FILTER_TYPE.TEXT,
      sort: SORT_TYPE.TEXT,
      sortOrd: 'ascend',
      render: (record, row) => (
        <CommentBox
          recordId={row.review_id}
          comments={comments}
          setComments={setComments}
        />
      ),
    },
  ];

  /**
   * Toggles marking an error ready to be deleted
   *
   * @param {Object} error
   * @param {boolean} isSelected
   */
  const onSelectErrorToDelete = (error, isSelected) => {
    if (isSelected) {
      setErrorsToBeDeleted((errorReviewIdsToBeDeleted) => [
        ...errorReviewIdsToBeDeleted,
        error.review_id,
      ]);
    } else {
      setErrorsToBeDeleted((errorReviewIdsToBeDeleted) => {
        const index = errorReviewIdsToBeDeleted.indexOf(error.review_id);
        return [
          ...errorReviewIdsToBeDeleted.slice(0, index),
          ...errorReviewIdsToBeDeleted.slice(index + 1),
        ];
      });
    }
  };

  const rowSelection = {
    selectedRowKeys: errorsToBeDeleted,
    onSelect: onSelectErrorToDelete,
    columnTitle: 'Delete',
    fixed: true,
    hideSelectAll: true,
  };

  function onChangeSize(current, pageSize) {
    setSize(pageSize);
    localStorage.setItem('pageSize', pageSize);
  }

  return (
    <section className="container">
      <div className="flex">
        <div className="width50 header textRight">Error Dashboard </div>
        <div className="width50 textRight">
          <Button
            type="link"
            className="textRightButton"
            onClick={() => {
              history.push({
                pathname: '/failedRecords',
              });
            }}
          >
            Failed Records
          </Button>
        </div>
      </div>
      <CustomTable
        data={data}
        filterEnabled={true}
        isCSVExport={true}
        emptyText="No Data"
        columns={columns}
        pagination={{
          onShowSizeChange: onChangeSize,
          showSizeChanger: true,
          defaultCurrent: 1,
          pageSize: size,
        }}
        rowKey={(record) => record.review_id}
        rowSelection={rowSelection}
      />
      {Boolean(data.length) && (
        <div className="buttonContainer">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={onPressSave(comments)}
          >
            Save
          </Button>
        </div>
      )}
    </section>
  );
}
export default ErrorDashboard;
