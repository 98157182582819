import axios from 'axios';
import { getHeaders } from '../utils/axiosUtils';
import {
  DELETE_ERROR_DETAILS_BY_REVIEW_IDS,
  GET_ALL_ASSIGNEE,
  GET_DASHBOARD,
  GET_REVIEW_BY_REVIEW_ID,
  GET_SFREVIEW_BY_REVIEW_ID,
  UPDATE_ERROR_DASHBOARD_COMMENTS,
  UPDATE_ERROR_DETAILS,
  UPDATE_ERROR_DETAIL_COMMENTS,
  UPDATE_RECORD,
  UPDATE_SF_ERROR_DETAILS,
  UPDATE_SF_ERROR_DETAIL_COMMENTS,
} from '../components/shared/ApiConstants';

/**
 * Update the comments on error records
 * @param {Record<number, string>} commentsToUpdate
 * @param {string} accessToken
 * @returns {Promise<void>}
 */
export async function updateComments(
  oldComments,
  commentsToUpdate,
  accessToken
) {
  const diffComments = getCommentDiff(oldComments, commentsToUpdate);
  if (Object.keys(diffComments).length > 0) {
    const headers = getHeaders(accessToken);
    await axios.put(
      UPDATE_ERROR_DASHBOARD_COMMENTS,
      {
        commentsToUpdate: diffComments,
      },
      { headers }
    );
  }
}

/**
 * We split the comments update into a separate function to avoid 413 (Payload Too Large) errors
 * that can occur when sending too many comments at once in the main updateComments function.
 * This allows us to handle large comment updates in smaller chunks.
 */
function getCommentDiff(oldComments, commentsToUpdate) {
  return Object.fromEntries(
    Object.entries(commentsToUpdate).filter(
      ([reviewId, newComment]) =>
        !oldComments[reviewId] || oldComments[reviewId] !== newComment
    )
  );
}

/**
 * Get all error records
 * @param {string} accessToken
 * @returns {Promise<Array<Object>>} errorRecords
 */
export async function getAllErrorRecords(accessToken) {
  const headers = getHeaders(accessToken);
  const { data: errorRecords } = await axios.get(GET_DASHBOARD, { headers });

  return errorRecords;
}

/**
 * Get list of all assignees
 * @param {string} accessToken
 * @returns {Promise<Array<Object>>} assignees
 */
export async function getAllAssignees(accessToken) {
  const headers = getHeaders(accessToken);
  const { data: assignees } = await axios.get(GET_ALL_ASSIGNEE, { headers });

  return assignees;
}

/**
 * Get additional details for an error
 * @param {number} reviewId
 * @param {string} accessToken
 * @returns {Promise<Object>}
 */
export async function getAdditionalErrorDetailsBySalesForceReviewId(
  reviewId,
  accessToken
) {
  const headers = getHeaders(accessToken);
  const { data: additionalErrorDetails } = await axios.get(
    GET_SFREVIEW_BY_REVIEW_ID(reviewId),
    { headers }
  );

  return additionalErrorDetails;
}

/**
 * Update Sales Force error details
 * @param {Array<{ id: number, review_id: number, gln: string, sfdc: number }>} errorRecords
 * @param {string} accessToken
 * @return {Promise<Object>}
 */
export async function updateSalesForceErrorDetails(errorRecords, accessToken) {
  const headers = getHeaders(accessToken);
  const {
    data: { errorDetails },
  } = await axios.put(
    UPDATE_SF_ERROR_DETAILS,
    {
      errorRecords,
    },
    { headers }
  );

  return errorDetails;
}

/**
 * Update error record
 * @param {{ assigneeId: number, originalId: number, status: string, reviewId: number }}} payload
 * @param {string} accessToken
 * @return {Promise<void>}
 */
export async function updateErrorRecord(payload, accessToken) {
  const headers = getHeaders(accessToken);
  await axios.put(UPDATE_RECORD, payload, { headers });
}

/**
 * Update SalesForce error details comments
 * @param {Record<number, string>} commentsToUpdate
 * @param {string} accessToken
 * @return {Promise<void>}
 */
export async function updateSalesForceErrorDetailComments(
  commentsToUpdate,
  accessToken
) {
  const headers = getHeaders(accessToken);
  await axios.put(
    UPDATE_SF_ERROR_DETAIL_COMMENTS,
    { commentsToUpdate },
    { headers }
  );
}

/**
 * Get review by reviewId
 * @param {number} reviewId
 * @param {string} accessToken
 * @return {Promise<void>}
 */
export async function getReviewByReviewId(reviewId, accessToken) {
  const headers = getHeaders(accessToken);
  const { data: review } = await axios.get(GET_REVIEW_BY_REVIEW_ID(reviewId), {
    headers,
  });

  return review;
}

/**
 * Update error records
 * @param {Array<{bp_id: number, id: number, review_id: number}>} errorRecords
 * @param {string} accessToken
 * @return {Promise<Array<Object>>} errorDetails
 */
export async function updateErrorDetails(errorRecords, accessToken) {
  const headers = getHeaders(accessToken);
  const {
    data: { errorDetails },
  } = await axios.put(UPDATE_ERROR_DETAILS, { errorRecords }, { headers });

  return errorDetails;
}

/**
 * Update error detail comments for multiple errors
 * @param {Record<string, any>} commentsToUpdate
 * @param {string} accessToken
 * @return {Promise<void>}
 */
export async function updateErrorDetailComments(commentsToUpdate, accessToken) {
  const headers = getHeaders(accessToken);
  await axios.put(
    UPDATE_ERROR_DETAIL_COMMENTS,
    {
      commentsToUpdate,
    },
    { headers }
  );
}

/**
 * Delete error details by reviewIds
 * @param {number[]} errorsToBeDeleted - array of error reviewIds to delete by
 * @param {string} accessToken
 * @returns {Promise<void>}
 */
export async function deleteErrorDetails(errorsToBeDeleted, accessToken) {
  const headers = getHeaders(accessToken);

  if (errorsToBeDeleted?.length > 0)
    await axios.post(
      DELETE_ERROR_DETAILS_BY_REVIEW_IDS,
      {
        reviewIds: errorsToBeDeleted,
      },
      { headers }
    );
}
